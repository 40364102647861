@import 'variables.scss';

@media print {
    html {
	    margin: initial;
        height: auto;
    }

    body {
	    margin: initial;
	    padding: initial;
        background-color: #ffffff;
        height: auto;
    }

    .container {
        .header {
            display: none;
        }

        .content {
            position: initial;
            top: 0;

            .menuContainer {
                display: none;
            }

            .main [ng-view] {
                padding: 0;
            }
        }
    }
}