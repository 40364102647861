@import 'variables.scss';

html, .ui-widget {
	input, keygen, select, button, textarea, .value {
		font-family: $font;
		font-size: $fontSize;

		&:not([type="button"]), &:not([type="submit"]), &:not([type="radio"]), &:not([type="checkbox"]) {
			padding: 3px;
			border-radius: 3px;
		}

		&[type="radio"], &[type="checkbox"] {
			margin: 6px 4px 6px 2px;
		}
	}
}

.form {
    margin-bottom: $contentMargin;
    
    label, .field {
        > * {
            display: inline-block;
            vertical-align: top;
        }
    }

    label:not(.inline), .field:not(.inline) {
        line-height: 18px;
        
        &::after {
            display: block;
            visibility: hidden;
            content: '\A';
            white-space: pre;
            font-size: 0;
            line-height: 0;
            margin-bottom: 5px;
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            display: block;
        }

        .caption {
            width: 200px;
        }
    }

    .item label, .item .field, label.inline, .field.inline {
        margin-right: 16px;
        display: inline-block;
        vertical-align: top;

        &::after {
            content: '';
            white-space: initial;
            line-height: 0;
        }
    }

     .item label, .item .field {
        min-height: 0;

        .caption {
            margin: 0 5px 0 0;
            width: auto;
        }
        
        > * {
            vertical-align: middle;
        }
    }
}

.toolbar {
    label, .field {
        > * {
            display: inline-block;
            vertical-align: top;
        }
    }

    label, .field {
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
        min-height: 0;

        &::after {
            content: '';
            white-space: initial;
            line-height: 0;
        }

        .caption {
            margin: 0 5px 0 0;
            width: auto;
        }

        > * {
            vertical-align: middle;
        }
    }
    
    button .mdi {
        vertical-align: top;
        margin-right: 3px;
    }
}

.form fieldset, fieldset.form {
    margin: 0;
    padding: 0 0 15px 0;
    border-style: none;
}

.form, .toolbar, .options {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .caption {
        margin-top: 4px;
        margin-right: 8px;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .ng-invalid:not(fieldset) {
        box-shadow: 0 0 1px 1px #ff5555;
    }

    input[type="button"], input[type="submit"], button {
        padding: 3px 9px;
        border: 2px solid transparent;
        border-radius: 3px;
        background: #eeeeee;
    }

    input.hasDatepicker {
        width: 200px;
    }
}

input[type="submit"][value="Save"], input[type="submit"].green, input[type="button"].green, button.green {
	background: #89c403 linear-gradient(to bottom, #89c403 5%, #77a809 100%) repeat scroll 0 0;
	color: #ffffff;

	&[disabled] {
		background: #cccccc;
		color: #eeeeee;
	}
}

input[type="button"][disabled] {
    background: #cccccc;
    color: #eeeeee;
}

input[type="button"].secondaryAction:not([disabled]) {
    background: #46b8da linear-gradient(to bottom, #46b8da 5%, #42b4cb 100%) repeat scroll 0 0;
    color: #ffffff;
}

input[type="button"][value="Cancel"]:not([disabled]), input[type="button"][value="Reset"]:not([disabled]) {
    background: #cc0000 linear-gradient(to bottom, #cc0000 5%, #aa0000 100%) repeat scroll 0 0;
    color: #ffffff;
}
