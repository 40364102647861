div.jnotify-container {
	position: fixed;
	top: 11px;
	right: 20px;
	width: 460px;
	z-index: 100000;
}

div.jnotify-container div.jnotify-notification {
	position: relative;
}

div.jnotify-container div.jnotify-dismissible {
	cursor: pointer;
}

div.jnotify-container div.jnotify-notification div.jnotify-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #e3f0db;
	filter: alpha(opacity=90);
	-moz-opacity: 0.90;
	opacity: 0.90;
	z-index: 1;
    box-shadow: 5px 5px 5px #333;
    border-radius: 4px;
}

div.jnotify-container div.jnotify-notification a.jnotify-close,
div.jnotify-container div.jnotify-notification a.jnotify-close:link,
div.jnotify-container div.jnotify-notification a.jnotify-close:visited,
div.jnotify-container div.jnotify-notification a.jnotify-close:focus,
div.jnotify-container div.jnotify-notification a.jnotify-close:hover {
	position: absolute;
	top: 0;
	right: 5px;
	padding: 0 5px;
	font: bold 26px Verdana,Arial,Helvetica,sans-serif;
	line-height: 26px;
	color: #567b1b;
	text-decoration: none;
	z-index: 3;
	cursor: pointer;
}

div.jnotify-container div.jnotify-notification div.jnotify-message {
	position: relative;
	z-index: 2;
	padding: 8px 30px 8px 10px;
	text-align: center;
	color: #567b1b;
    font-size: 16px;
    margin-bottom: 10px;
}

/* notification type == "error" */
div.jnotify-container div.jnotify-notification-error div.jnotify-background {
	background-color: #d79eac;
}

div.jnotify-container div.jnotify-notification-error a.jnotify-close,
div.jnotify-container div.jnotify-notification-error div.jnotify-message {
	color: #a72947 !important;
}

/* notification type == "warning" */
div.jnotify-container div.jnotify-notification-warning div.jnotify-background {
	background-color: #fff7d1;
}

div.jnotify-container div.jnotify-notification-warning a.jnotify-close,
div.jnotify-container div.jnotify-notification-warning div.jnotify-message {
	color: #c2a928 !important;
}
